import { useState, useEffect, useCallback } from 'react';

const CACHE_DURATION = 3 * 60 * 1000; // 3 minutos en milisegundos
const RETRY_DELAY = 2000; // 2 segundos de espera entre reintentos
const MAX_RETRIES = 3; // Máximo número de reintentos

const useExchangeRates = (currency) => {
  const [rate, setRate] = useState(0);
  const [usdToArs, setUsdToArs] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ratesCache, setRatesCache] = useState(() => {
    try {
      const savedCache = localStorage.getItem('ratesCache');
      return savedCache ? JSON.parse(savedCache) : {};
    } catch {
      return {};
    }
  });

  const fetchWithRetry = useCallback(async (url, options = {}, retries = 0) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 segundos timeout

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        }
      });

      clearTimeout(timeoutId);

      if (response.status === 429) {
        throw new Error('RATE_LIMIT');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      clearTimeout(timeoutId);

      if (error.message === 'RATE_LIMIT' && retries < MAX_RETRIES) {
        // Esperar más tiempo si es error de límite de velocidad
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, retries)));
        return fetchWithRetry(url, options, retries + 1);
      }

      if (retries < MAX_RETRIES && error.name !== 'AbortError') {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return fetchWithRetry(url, options, retries + 1);
      }
      throw error;
    }
  }, []);

  const updateCache = useCallback((newData) => {
    try {
      setRatesCache(prev => {
        const newCache = { ...prev, ...newData };
        localStorage.setItem('ratesCache', JSON.stringify(newCache));
        return newCache;
      });
    } catch (error) {
      console.error('Error updating cache:', error);
    }
  }, []);

  const fetchRate = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    // Verificar caché
    const cachedData = ratesCache[currency];
    if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
      setRate(cachedData.rate);
      setUsdToArs(cachedData.usdToArs || 1);
      setIsLoading(false);
      return;
    }

    try {
      if (currency === 'ARS') {
        const [arsData, btcData] = await Promise.all([
          fetchWithRetry('https://api.bluelytics.com.ar/v2/latest'),
          fetchWithRetry('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd')
        ]);

        const arsRate = arsData.blue.value_avg;
        const usdRate = btcData.bitcoin.usd;

        updateCache({
          [currency]: {
            rate: usdRate,
            usdToArs: arsRate,
            timestamp: Date.now()
          }
        });

        setRate(usdRate);
        setUsdToArs(arsRate);
      } else {
        const data = await fetchWithRetry(
          `https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=${currency.toLowerCase()}`
        );

        const newRate = data.bitcoin[currency.toLowerCase()];
        
        updateCache({
          [currency]: {
            rate: newRate,
            usdToArs: 1,
            timestamp: Date.now()
          }
        });

        setRate(newRate);
        setUsdToArs(1);
      }
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      setError(error.message);
      
      // Usar caché expirado como fallback
      if (cachedData) {
        console.log('Using expired cache due to error');
        setRate(cachedData.rate);
        setUsdToArs(cachedData.usdToArs || 1);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currency, ratesCache, fetchWithRetry, updateCache]);

  useEffect(() => {
    fetchRate();
  }, [fetchRate]);

  const clearCache = useCallback(() => {
    setRatesCache({});
    localStorage.removeItem('ratesCache');
  }, []);

  return {
    rate,
    usdToArs,
    isLoading,
    error,
    clearCache,
    isCached: Boolean(ratesCache[currency] && Date.now() - ratesCache[currency].timestamp < CACHE_DURATION)
  };
};

export default useExchangeRates;